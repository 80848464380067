<template>
    <div class="preview_area show_sidebar active" v-if="modelValue">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="$emit('update:modelValue', false)"><i class="fas fa-long-arrow-alt-left"></i></button>
            <ul class="tabs_btn">
                <li :class="tab === 'general' ? 'active' : ''" @click="tab = 'general'">General</li>
                <li :class="tab === 'single' ? 'active' : ''" @click="tab = 'single'">Single Purchases</li>
                <li :class="tab === 'subscriptions' ? 'active' : ''" @click="tab = 'subscriptions'">Subscriptions</li>
                <li :class="tab === 'bump' ? 'active' : ''" @click="tab = 'bump'">Order Bumps</li>
                <li :class="tab === 'abandoned' ? 'active' : ''" @click="tab = 'abandoned'">Abandoned Cart</li>
            </ul>
            <div class="tabs_content">
                <general-tab :selected-client="selectedClient" v-show="tab === 'general'"></general-tab>

                <div class="content_area" v-if="tab === 'single'">
                    <single-purchase :selected-client="selectedClient"></single-purchase>
                </div>
                <div class="content_area" v-if="tab === 'subscriptions'">
                    <subscription-component :selected-client="selectedClient"></subscription-component>
                </div>
                <div class="content_area" v-if="tab === 'bump'">
                    <bump-order :selected-client="selectedClient"></bump-order>
                </div>
                <div class="content_area" v-if="tab === 'abandoned'">
                    <abandoned-cart :selected-client="selectedClient"></abandoned-cart>
                </div>
            </div>
            <div class="side_info" v-if="tab === 'general'">
                <div class="ltv_card">
                    <div>
                        <h4 class="sub_heading">Lifetime Value</h4>
                        <h2 class="amt">{{ companyCurrencySymbol }}{{ clientAnalytics.total_ltv ? parseFloat(clientAnalytics.total_ltv).toFixed(2) : 0 }}</h2>
                    </div>
                    <div class="icon"><img src="@/assets/images/currency.svg" alt=""></div>
                </div>
                <h3 class="sub_header mt-5">Tags</h3>
                <div class="no_result" v-if="(clientAnalytics.tags && !clientAnalytics.tags.length) || !clientAnalytics.tags">No active tags.</div>
                <ul class="tags" v-if="clientAnalytics.tags && clientAnalytics.tags.length">
                    <li v-for="(tag, t) of clientAnalytics.tags" :key="t"><h4>{{ tag.name }}</h4></li>
                </ul>
                <h3 class="sub_header mt-5">Analytics</h3>
                <ul class="analytic_list">
                    <li>
                        <div class="analytic">
                            <h5>Purchase <span>{{ clientAnalytics.total_purchase_count || clientAnalytics.total_bump_purchase_count ? clientAnalytics.total_purchase_count + clientAnalytics.total_bump_purchase_count : 0 }}</span></h5>
                            <div class="icon">
                                <i class="fas fa-shopping-bag"></i>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="analytic">
                            <h5>Refunds <span>{{ clientAnalytics.total_refund_count ? clientAnalytics.total_refund_count : 0 }}</span></h5>
                            <div class="icon">
                                <i class="fas fa-dollar-sign"></i>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="analytic">
                            <h5>Refund Rate <span>{{ getRefundRate() }}%</span></h5>
                            <div class="icon">
                                <i class="fas fa-percentage"></i>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="analytic">
                            <h5>Subscriptions <span>{{ clientAnalytics.total_subscriptions_count ? clientAnalytics.total_subscriptions_count : 0 }}</span></h5>
                            <div class="icon">
                                <i class="fas fa-user"></i>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="analytic">
                            <h5>Sub Payments <span>{{ clientAnalytics.sub_payments ? clientAnalytics.sub_payments : '0.00' }}</span></h5>
                            <div class="icon">
                                <i class="fas fa-dollar-sign"></i>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="analytic">
                            <h5>Rentention Rate <span>{{ clientAnalytics.rentention_rate ? clientAnalytics.rentention_rate : '0%' }}</span></h5>
                            <div class="icon">
                                <i class="fas fa-percentage"></i>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const GeneralTab = defineAsyncComponent(() => import('@/pages/checkout/components/client/GeneralTab'))
    const SinglePurchase = defineAsyncComponent(() => import('@/pages/checkout/components/client/SinglePurchase'))
    const SubscriptionComponent = defineAsyncComponent(() => import('@/pages/checkout/components/client/Subscription'))
    const BumpOrder = defineAsyncComponent(() => import('@/pages/checkout/components/client/BumpOrder'))
    const AbandonedCart = defineAsyncComponent(() => import('@/pages/checkout/components/client/AbandonedCart'))

    export default {
        name: 'Checkout Client Profile',

        data () {
            return {
                tab : 'general',
            }
        },

        props: {
            modelValue: Boolean,
            selectedClient: {
                type: Object,
                default: () => {},
            },
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;
                    vm.tab   = 'general';

                    vm.getClientAnalytics(vm.selectedClient.id);
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            }
        },

        components:{
            GeneralTab,
            SinglePurchase,
            SubscriptionComponent,
            BumpOrder,
            AbandonedCart,
        },

        computed: mapState ({
            clientAnalytics: state => state.checkoutModule.clientAnalytics,
            clientAnalyticsLoader: state => state.checkoutModule.clientAnalyticsLoader,
            companyCurrencySymbol: state => state.checkoutModule.companyCurrencySymbol,
        }),

        methods: {
            ...mapActions({
                getClientAnalytics: 'checkoutModule/getClientAnalytics',
            }),

            getRefundRate () {
                const vm = this;

                const refundRate = ((vm.clientAnalytics.total_refund_count + vm.clientAnalytics.total_bump_refund_count) * 100) / (vm.clientAnalytics.total_purchase_count + vm.clientAnalytics.total_bump_purchase_count);

                return refundRate ? parseFloat(refundRate).toFixed(2) : 0;
            }
        }
    }
</script>

<style scoped>
    .side_info{
        flex: 0 0 340px;
        padding: 30px 40px;
        background: #fff;
        border-left: 1px solid #e9e9e9;
        overflow-y: auto;
    }
    .side_info::-webkit-scrollbar{
        width: 4px;
        background: transparent;
    }
    .side_info::-webkit-scrollbar-thumb{
        background: #bdbdbd;
        border-radius: 2px;
    }
    .side_info .ltv_card{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
        gap: 20px;
        border-bottom: 1px solid #e9e9e9;
        padding-bottom: 20px;
    }
    .side_info .ltv_card .icon{
        width: 30px;
    }
    .side_info .ltv_card .sub_heading {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 10px;
    }
    .side_info .ltv_card .amt {
        font-size: 25px;
        line-height: 30px;
        font-weight: 300;
        color: #121525;
    }
    .side_info .tags {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }
    .side_info .tags li h4 {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        background: #2f7eed;
        color: #fff;
        margin: 0;
        border-radius: 3px;
        padding: 5px 12px;
    }
    .side_info .analytic_list{
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 1px 0;
    }
    /* .side_info .analytic_list li{
        width: calc(50% - 5px);
    } */
    .side_info .analytic{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: 12px;
        padding: 15px;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        box-shadow: 0 1px 3px rgba(0,0,0,0.04);
    }
    .side_info .analytic .icon{
        flex-shrink: 0;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f5f5f5;
        color: #ababab;
        font-size: 13px;
    }
    .side_info .analytic h5{
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
    }
    .side_info .analytic h5 span{
        font-size: 20px;
        line-height: 25px;
        color: #121525;
        display: block;
        padding-top: 10px;
    }
    .profile_wpr{
        display: flex;
        gap: 50px;
    }
    .profile_left {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: sticky;
        top: 35px;
        align-self: flex-start;
    }
    .profile_left .user_img {
        display: flex;
        align-items: center;
        gap: 15px;
        position: relative;
        margin-bottom: 20px;
    }
    .profile_left .user_img img{
        width: 120px;
        height: 120px;
        border-radius: 50%;
        -o-object-fit: cover;
        object-fit: cover;
        border: 1px solid #eee;
    }
    .profile_left a{
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        text-decoration: none;
        color: #2f7eed;
        display: block;
        border: 1px solid #7cb1fa;
        padding: 5px 10px;
        border-radius: 14px;
    }
    :deep(.tab_content.button-uploader) {
        width: 25px;
        height: 25px;
        font-size: 11px;
        text-align: center;
        line-height: 22px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #c4c4c4;
        color: #121525;
        position: absolute;
        right: 10px;
        bottom: 0;
        cursor: pointer;
    }
    .profile_right{
        flex: 0 1 800px;
    }
    .col-2, .col-3{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
    }
    .col-2 .form_grp{
        flex: 0 0 50%;
        margin: 0;
    }
    .col-3 .form_grp{
        flex: 0 0 33.333%;
        margin: 0;
    }
    .form_grp h4 {
        font-size: 15px;
        line-height: 22px;
        color: #5a5a5a;
        font-weight: 300;
        margin-top: 12px;
        display: flex;
    }
    .form_grp h4 .btn{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        background: #2f7eed;
        color: #fff;
        cursor: pointer;
        margin-left: 10px;
    }
    .edit_btn {
        font-size: 10px;
        line-height: 12px;
        background: #2f7eed;
        text-transform: uppercase;
        color: #fff;
        font-weight: 500;
        padding: 8px 20px;
        border-radius: 20px;
        cursor: pointer;
        font-family: 'Inter', sans-serif;
        margin: 0 0 15px;
    }

    .result_wpr table td:first-child, .result_wpr table th:first-child {
        width: auto;
        padding: 20px;
        text-align: left;
    }
    .result_wpr table td a{
        color: #2f7eed;
        font-weight: 500;
        cursor: pointer;
    }
    .result_wpr table td .action_list{
        display: flex;
        gap: 10px;
    }
    .result_wpr table td .action_list li i{
        color: #2f7eed;
        border-radius: 50%;
        font-size: 15px;
    }
    .result_wpr table td label{
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        border: 1px solid #9FB1CF;
        border-radius: 11px;
        padding: 3px 10px;
    }
    .form_grp input.no_spin::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    .form_grp input[type=number].no_spin {
        -moz-appearance: textfield;
    }
    .modal .refund_from{
        height: calc(100vh - 150px);
        display: flex;
        flex-direction: column;
    }
    p.note{
        padding: 15px 20px;
        background: rgba(47, 126, 237, 0.05);
        border: 1px solid rgba(47, 126, 237, 0.1);
        border-radius: 5px;
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #121525;
    }
</style>
